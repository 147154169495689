import React from "react";
import { Link, HeadFC } from "gatsby";
import SEO from "../seo";
import logo from "../images/logo/panzero-logo.png";

const NotFoundPage = () => {
  return (
    <main className="grid place-content-center h-screen p-8">
      <Link to="/">
        <img className="w-64 mb-8 mx-auto" src={logo} />
      </Link>
      <h1 className="text-brown-500 uppercase text-5xl whitespace-pre-line">
        404{"\n"} Page not found
      </h1>
      <Link to="/" className="text-red-500 uppercase text-4xl mt-8 snake">
        Go home
      </Link>
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <SEO title="Not found" />;
